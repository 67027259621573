<template>
  <div>
    <FreshChatLauncher
      v-show="initializeChat"
      @closeChat="handleCloseChat"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import FreshChatLauncher from './FreshChatLauncher.vue'

export default {

  components: {
    FreshChatLauncher
  },

  props: {
    loadChat: {
      type: Boolean,
      default: false
    },

    hideChatButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initializeChat: false
    }
  },

  computed: {
    chatStarted () {
      return this.$store.state.application.chatStarted
    }
  },

  watch: {
    loadChat: {
      immediate: true,
      handler (val) {
        if (val === true) {
          this.handleInitialize()
        }
      }
    }
  },

  methods: {
    handleInitialize () {
      if (window.fcWidget) {
        this.$store.dispatch('setChatStarted', true)
        return window.fcWidget.open()
      }
      this.initializeChat = true
    },

    handleCloseChat () {
      this.$store.dispatch('setChatStarted', false)
    }
  }
}
</script>
