export const initialState = () => {
  return {
    cduid: '',
    submitted: false,
    leadSubmitted: false,
    leadCreated: false,
    leadSubmittedtoSF: false,
    uploadLater: false,
    additionalLeadInfoSubmitted: false,
    returningCustomerSubmitted: false,
    linearDocumentSteps: true,
    twilioFailed: false,
    inputFocused: false,
    dateTime: undefined,
    sessionId: undefined,
    skippedStep: false,
    isEzBotTriggered: false,
    shouldSkipEmailStep: false,
    masterBankingProvider: 'flinks',
    bankingProvider: 'flinks',
    routeChanged: false,
    creditScreen: 'secured',
    partnerRefId: '',
    flinksInstitutionId: undefined,
    plaidInstitutionId: undefined,
    isDeeperPrepop: false,
    isDeeperPrepopPrime: false,
    isPrepop: false,
    affiliateName: '',
    bankingSplitter: undefined,
    splArticles: {
      splArticleA: false,
      splArticleB: false,
      splArticleC: false,
      splArticleD: false,
      splArticleE: false,
      splArticleF: false,
      splLod: false,
      splAgree: false
    },
    chatStarted: false
  }
}

export default {
  state: initialState(),

  mutations: {
    SET_APP_CDUID (state, cduid) {
      state.cduid = cduid
    },

    SET_SUBMITTED (state, submitted) {
      state.submitted = submitted
    },

    SET_LEAD_SUBMITTED (state, leadSubmitted) {
      state.leadSubmitted = leadSubmitted
    },

    SET_LEAD_CREATED (state, leadCreated) {
      state.leadCreated = leadCreated
    },

    SET_LEAD_SUBMITTED_TO_SF (state, leadSubmitted) {
      state.leadSubmittedtoSF = leadSubmitted
    },

    SET_UPLOAD_LATER (state, uploadLater) {
      state.uploadLater = uploadLater
    },

    SET_ADDITIONAL_LEAD_INFO_SUBMITTED (state, additionalInfoSubmitted) {
      state.additionalLeadInfoSubmitted = additionalInfoSubmitted
    },

    SET_LINEAR_DOCUMENT_STEPS (state, linearDocumentSteps) {
      state.linearDocumentSteps = linearDocumentSteps
    },

    RESET_APPLICATION_STATE (state, initialState) {
      Object.keys(initialState).forEach(key => (state[key] = initialState[key]))
    },

    SET_RETURNING_CUSTOMER_SUBMITTED (state, returningCustomerSubmitted) {
      state.returningCustomerSubmitted = returningCustomerSubmitted
    },

    SET_TWILIO_FAILED (state, twilioFailed) {
      state.twilioFailed = twilioFailed
    },

    SET_INPUT_FOCUSED (state, inputFocused) {
      state.inputFocused = inputFocused
    },

    SET_SKIPPED_STEP (state, skippedStep) {
      state.skippedStep = skippedStep
    },

    RESET_DATE_TIME (state) {
      state.dateTime = new Date()
    },

    SET_SESSION_ID (state, sessionId) {
      state.sessionId = sessionId
    },

    SET_EZ_BOT_TRIGGERED (state, isEzBotTriggered) {
      state.isEzBotTriggered = isEzBotTriggered
    },

    SET_SHOULD_SKIP_EMAIL_STEP (state, shouldSkipEmailStep) {
      state.shouldSkipEmailStep = shouldSkipEmailStep
    },

    SET_BANKING_PROVIDER (state, bankingProvider) {
      state.bankingProvider = bankingProvider
    },

    SET_ROUTE_CHANGED (state, routeChanged) {
      state.routeChanged = routeChanged
    },

    SET_CREDIT_SCREEN (state, creditScreen) {
      state.creditScreen = creditScreen
    },

    SET_PARTNER_REF_ID (state, partnerRefId) {
      state.partnerRefId = partnerRefId
    },

    SET_FLINKS_INSTITUTION_ID (state, flinksInstitutionId) {
      state.flinksInstitutionId = flinksInstitutionId
    },

    SET_PLAID_INSTITUTION_ID (state, plaidInstitutionId) {
      state.plaidInstitutionId = plaidInstitutionId
    },

    SET_MASTER_BANKING_PROVIDER (state, masterBankingProvider) {
      state.masterBankingProvider = masterBankingProvider
    },

    SET_IS_DEEPER_PREPOP (state, isDeeperPrepop) {
      state.isDeeperPrepop = isDeeperPrepop
    },

    SET_AFFILIATE_NAME (state, affiliateName) {
      state.affiliateName = affiliateName
    },

    SET_BANKING_SPLITTER (state, bankingSplitter) {
      state.bankingSplitter = bankingSplitter
    },

    SET_IS_PREPOP (state, isPrepop) {
      state.isPrepop = isPrepop
    },

    SET_SPL_ARTICLES (state, splArticles) {
      state.splArticles = splArticles
    },

    SET_IS_DEEPER_PREPOP_PRIME (state, isDeeperPrepopPrime) {
      state.isDeeperPrepopPrime = isDeeperPrepopPrime
    },

    SET_CHAT_STARTED (state, chatStarted) {
      state.chatStarted = chatStarted
    }
  },

  actions: {
    setAppCDUID ({ commit }, cduid) {
      commit('SET_APP_CDUID', cduid)
    },

    setSubmitted ({ commit }, submitted) {
      commit('SET_SUBMITTED', submitted)
    },

    setLeadSubmitted ({ commit }, leadSubmitted) {
      commit('SET_LEAD_SUBMITTED', leadSubmitted)
    },

    setLeadSubmittedToSF ({ commit }, leadSubmitted) {
      commit('SET_LEAD_SUBMITTED_TO_SF', leadSubmitted)
    },

    setUploadLater ({ commit }, uploadLater) {
      commit('SET_UPLOAD_LATER', uploadLater)
    },

    setAdditionalLeadInfoSubmitted ({ commit }, leadSubmitted) {
      commit('SET_ADDITIONAL_LEAD_INFO_SUBMITTED', leadSubmitted)
    },

    setLeadCreated ({ commit }, leadCreated) {
      commit('SET_LEAD_CREATED', leadCreated)
    },

    setReturningCustomerSubmitted ({ commit }, returningCustomerSubmitted) {
      commit('SET_RETURNING_CUSTOMER_SUBMITTED', returningCustomerSubmitted)
    },

    setLinearDocumentSteps ({ commit }, linearDocumentSteps) {
      commit('SET_LINEAR_DOCUMENT_STEPS', linearDocumentSteps)
    },

    setTwilioFailed ({ commit }, twilioFailed) {
      commit('SET_TWILIO_FAILED', twilioFailed)
    },

    setInputFocused ({ commit }, inputFocused) {
      commit('SET_INPUT_FOCUSED', inputFocused)
    },

    setSkippedStep ({ commit }, skippedStep) {
      commit('SET_SKIPPED_STEP', skippedStep)
    },

    resetDateTime ({ commit }) {
      commit('RESET_DATE_TIME')
    },

    setSessionId ({ commit }, sessionId) {
      commit('SET_SESSION_ID', sessionId)
    },

    setEzBotTriggered ({ commit }, isEzBotTriggered) {
      commit('SET_EZ_BOT_TRIGGERED', isEzBotTriggered)
    },

    setShouldSkipEmailStep ({ commit }, shouldSkipEmailStep) {
      commit('SET_SHOULD_SKIP_EMAIL_STEP', shouldSkipEmailStep)
    },

    setBankingProvider ({ commit }, bankingProvider) {
      commit('SET_BANKING_PROVIDER', bankingProvider)
    },

    setRouteChanged ({ commit }, routeChanged) {
      commit('SET_ROUTE_CHANGED', routeChanged)
    },

    setCreditScreen ({ commit }, creditScreen) {
      commit('SET_CREDIT_SCREEN', creditScreen)
    },

    setPartnerRefId ({ commit }, partnerRefId) {
      commit('SET_PARTNER_REF_ID', partnerRefId)
    },

    setFlinksInstitutionId ({ commit }, flinksInstitutionId) {
      commit('SET_FLINKS_INSTITUTION_ID', flinksInstitutionId)
    },

    setPlaidInstitutionId ({ commit }, plaidInstitutionId) {
      commit('SET_PLAID_INSTITUTION_ID', plaidInstitutionId)
    },

    setMasterBankingProvider ({ commit }, masterBankingProivder) {
      commit('SET_MASTER_BANKING_PROVIDER', masterBankingProivder)
    },

    setIsDeeperPrepop ({ commit }, isDeeperPrepop) {
      commit('SET_IS_DEEPER_PREPOP', isDeeperPrepop)
    },

    setAffiliateName ({ commit }, affiliateName) {
      commit('SET_AFFILIATE_NAME', affiliateName)
    },

    setBankingSplitter ({ commit }, bankingSplitter) {
      commit('SET_BANKING_SPLITTER', bankingSplitter)
    },

    setIsPrepop ({ commit }, isPrepop) {
      commit('SET_IS_PREPOP', isPrepop)
    },

    setSplArticles ({ commit }, splArticles) {
      commit('SET_SPL_ARTICLES', splArticles)
    },

    setIsDeeperPrepopPrime ({ commit }, isDeeperPrepopPrime) {
      commit('SET_IS_DEEPER_PREPOP_PRIME', isDeeperPrepopPrime)
    },

    setChatStarted ({ commit }, chatStarted) {
      commit('SET_CHAT_STARTED', chatStarted)
    }
  }
}
