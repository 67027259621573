<template>
  <div class="app-shell">
    <AppBackground :module="module" />
    <!-- Hidden Fields (SID, AID, app_source, etc) -->
    <AppHiddenFields />

    <div
      class="app-shell__main"
      :class="{'restricted-nav-styles': shouldRestrictNav}"
    >
      <!-- App Nav -->
      <SpringNav
        :logo="require('@/assets/img/nav/logo.svg')"
        :easy-logo="require('@/assets/img/nav/easy-spring-logos.svg')"
        target="_blank"
        update-lendful-disclosure
        :variant="variant"
      />

      <!-- Progress Bar -->
      <AppProgressBar
        v-if="$_progress_visible"
        :theme="theme"
        :progress-label="$_progress_label"
        :total-steps="$_progress_totalSteps"
        :current-step-index="$_progress_currentStepIndex"
      />

      <!-- Router View -->
      <AppRouterViewContainer>
        <transition
          :name="transitionName"
          mode="out-in"
          @after-enter="$_stickyCTA_afterEnter"
          @before-leave="$_stickyCTA_beforeLeave"
        >
          <router-view
            :key="$route.path"
            :theme="theme"
            @show-bank-skip="$_stickyCTA_showBankSkip($event)"
          />
        </transition>
        <StepFooter
          v-if="$_stickyCTA_showFooter"
          fixed
        >
          <StepStickyCTA
            :theme="theme"
            :show-bank-skip="bankSkip"
            :continue-short-circuit="preventContinue"
          />
        </StepFooter>
      </AppRouterViewContainer>
    </div>

    <!-- App Footer -->
    <AppFooter
      class="xs-only:pb-48"
      :theme="theme"
    />
  </div>
</template>

<script>
import AppFooter from '@/components/layout/AppFooter'
import { SpringNav } from 'spring-ui'
import AppProgressBar from '@/components/common/progress/AppProgressBar'
import AppHiddenFields from '@/components/common/fields/AppHiddenFields'
import confirmationNumber from '@/mixins/confirmation-number'
import progress from '@/mixins/progress'
import stickyCTA from '@/mixins/sticky-cta'
import transitions from '@/mixins/transitions'
import theme from '@/mixins/theme'
import tracking from '@/mixins/tracking'
import navigation from '@/mixins/navigation'
import StepFooter from '@/components/common/step-layout/StepFooter'
import AppBackground from '@/components/common/background/AppBackground'
import AppRouterViewContainer from '@/components/common/AppRouterViewContainer'
import StepStickyCTA from '@/components/common/step-layout/StepStickyCTA'
import LocalStorage from '@/utilities/localStorage'
import submitLoans from '@/mixins/submit/submit-loans'

export default {
  // Name
  name: 'AppShellLoans',

  // Components
  components: {
    AppFooter,
    SpringNav,
    AppProgressBar,
    AppHiddenFields,
    StepFooter,
    StepStickyCTA,
    AppBackground,
    AppRouterViewContainer
  },

  // Mixins
  mixins: [
    confirmationNumber,
    progress,
    transitions,
    theme,
    stickyCTA,
    tracking,
    navigation,
    submitLoans
  ],

  // Props
  props: {
    module: {
      type: String,
      default: ''
    },
    canonicalModule: {
      type: String,
      default: ''
    },
    variant: {
      type: String,
      default: ''
    }
  },

  head () {
    return {
      link: [
        { rel: 'canonical', href: this.$_tracking_canonicalURL(this.canonicalModule || this.module) }
      ]
    }
  },

  computed: {
    applicationStarted () {
      const { leadCreated, leadSubmitted } = this.$store.state.application
      return leadCreated && !leadSubmitted
    },

    isPersonalLoan () {
      return this.$store.getters['loans/isPersonalLoan']
    },

    isDeeperPrepop () {
      return this.$store.state.application.isDeeperPrepop
    },

    isDeeperPrepopPrime () {
      return this.$store.state.application.isDeeperPrepopPrime
    },

    shouldRestrictNav () {
      const excludedRoutes = [
        '/personal-loans/sorry',
        'apply/personal-loans/sorry',
        '/personal-loans/application-success',
        '/apply/personal-loans/application-success'
      ]
      return !excludedRoutes.includes(this.$route.path) && this.isPersonalLoan && (this.isDeeperPrepop || this.isDeeperPrepopPrime)
    }
  },

  watch: {
    $route (to) {
      this.$store.dispatch('setRouteChanged', true)
    }
  },

  mounted () {
    window.addEventListener('beforeunload', (event) => this.confirmPageBounce(event), { capture: true })
    this.handleEmailSkip()
    if (this.$route.query.id) this.handlePIIAutofill()
  },

  methods: {
    async handleEmailSkip () {
      if (this.$store.state.application.shouldSkipEmailStep) {
        const { variation } = this.$route.matched[0].meta
        const tag = variation === 'v1' ? '' : '-' + variation
        this.$store.dispatch('setShouldSkipEmailStep', false)
        this.$router.replace(
          { path: `/personal-loans${tag}/name`,
            query: { ...this.$route.query } }
        )
      }
    },

    async handlePIIAutofill () {
      const piiData = await LocalStorage.getItem('partner_pii')
      if (piiData) this.$_loans_updateCustomerStore(piiData)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/step-transitions';

.app-shell {
  @apply overflow-x-hidden relative flex flex-col;

  &__main {
    @apply pt-60 min-h-[100vh];

    @screen sm {
      @apply pt-90;
    }
  }
}
</style>
