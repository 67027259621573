import dayjs from 'dayjs'
export default {

  computed: {

    locationState () {
      return this.$store.state.location
    },

    emailError () {
      return this.$store.state.validations.emailError
    },

    email () {
      return this.$store.state.customer.email
    },

    firstName () {
      return this.$store.state.customer.first_name
    },

    lastName () {
      return this.$store.state.customer.last_name
    },

    annualIncome () {
      return this.$store.state.employment.annual_income
    },

    monthlyIncome () {
      return this.$store.state.employment.monthly_income
    },

    hourlyIncome () {
      return this.$store.state.employment.hourly_income
    },

    hoursPerWeek () {
      return this.$store.state.employment.hours_per_week
    },

    employerYears () {
      return this.$store.state.employment.employer_years
    },

    employerMonths () {
      return this.$store.state.employment.employer_months
    },

    cPostVerified () {
      return this.locationState.c_post_verified
    },

    fallbackData () {
      return this.$store.getters.fallbackData
    },

    birthData () {
      return this.$store.getters.birthData
    },

    homePhone () {
      return this.$store.state.customer.home_phone
    },

    phoneVerificationCode () {
      return this.$store.state.customer.phoneVerificationCode
    },

    id () {
      return this.$store.state.documents.id
    },

    poa () {
      return this.$store.state.documents.poa
    },

    proofOfIncome () {
      return this.$store.state.documents.proofOfIncome
    },

    homeValue () {
      return this.$store.state.mortgages.home_value
    },

    amountOwing () {
      return this.$store.state.mortgages.amount_owing
    },

    file1 () {
      return this.$store.state.documents.proofOfIncomeFile1
    },

    file2 () {
      return this.$store.state.documents.proofOfIncomeFile2
    }

  },

  methods: {
    isRequired: (value) => {
      return value !== undefined && value !== null && value.toString().trim() !== ''
    },

    isEmail: (value) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    },

    hasMinimumCharacters: (value, length) => {
      return value.length >= length
    },

    hasMaximumCharacters: (value, length) => {
      return value.length <= length
    },

    hasMinCurrency (value, minAmount) {
      const cleanNumber = parseFloat(value.replace(/,|_/g, ''))
      return !isNaN(cleanNumber) && cleanNumber >= minAmount
    },

    hasMaxCurrency (value, maxAmount) {
      const cleanNumber = parseFloat(value.replace(/,|_/g, ''))
      return !isNaN(cleanNumber) && cleanNumber <= maxAmount
    },

    hasMaxDigits (value, maxDigits) {
      const cleanNumber = value.replace(/,|_/g, '')
      return cleanNumber.replace(/\D/g, '').length <= maxDigits
    },

    hasMinDigits (value, minDigits) {
      const cleanNumber = value.replace(/,|_/g, '')
      return cleanNumber.replace(/\D/g, '').length >= minDigits
    },

    isValidPostalCode (value) {
      return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value)
    },

    isValidFileExtension (value) {
      const extension = value.name.split('.').pop()
      return /jpg|jpeg|png|gif|pdf/.test(extension.toLowerCase())
    },

    isValidFileType (value) {
      return /image\/jpg|image\/jpeg|image\/png|image\/gif|application\/pdf/
        .test(value.type.toLowerCase())
    },

    isFileUploading () {
      return this.uploading
    },

    isFileUploadedSuccess () {
      return !this.uploadFailed
    },

    isValidFileSize (value) {
      const maxFileSize = 40000000
      return value.size.toFixed(2) < maxFileSize
    },

    isOfAge (value) {
      const ageOfMajority = {
        AB: 18,
        BC: 19,
        MB: 18,
        NB: 19,
        NL: 19,
        NS: 19,
        NT: 19,
        NU: 19,
        ON: 18,
        PE: 18,
        QC: 18,
        SK: 18,
        YT: 19
      }

      const province = this.$store.state.location.province

      const minAge = province ? ageOfMajority[province] : 18

      const minDate = dayjs().subtract(100, 'year')
      const maxDate = dayjs().subtract(minAge, 'year')
      return value.isAfter(minDate) && value.isBefore(maxDate)
    },

    isValidDate (value) {
      const rawDate = value.replace(/_| /g, '')
      const parsedDate = dayjs(rawDate)
      const formattedDate = parsedDate.format('MM/DD/YYYY')
      return parsedDate.isValid() && rawDate === formattedDate && rawDate.match(/\d\d\/\d\d\/\d\d\d\d/)
    },

    isValidPhone (value) {
      const cleanValue = value.trim().replace(/ /g, '')
      return /^(\+?1\s?-?)?(\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4}$/.test(cleanValue)
    },

    validateEmail () {
      if (!this.isRequired(this.email)) {
        this.$store.dispatch('setEmailError', 'This field is required.')
        return false
      } else if (!this.isEmail(this.email)) {
        this.$store.dispatch('setEmailError', 'Enter a valid email address')
        return false
      } else {
        this.$store.dispatch('setEmailError', '')
        return true
      }
    },

    validateFirstName () {
      if (!this.isRequired(this.firstName)) {
        this.$store.dispatch('setFirstNameError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(this.firstName, 2)) {
        this.$store.dispatch('setFirstNameError', 'Please enter at least 2 characters.')
        return false
      } else if (!this.hasMaximumCharacters(this.firstName, 30)) {
        this.$store.dispatch('setFirstNameError', 'Please enter at most 30 characters.')
        return false
      } else {
        this.$store.dispatch('setFirstNameError', '')
        return true
      }
    },

    validateLastName () {
      if (!this.isRequired(this.lastName)) {
        this.$store.dispatch('setLastNameError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(this.lastName, 2)) {
        this.$store.dispatch('setLastNameError', 'Please enter at least 2 characters.')
        return false
      } else if (!this.hasMaximumCharacters(this.lastName, 30)) {
        this.$store.dispatch('setLastNameError', 'Please enter at most 30 characters.')
        return false
      } else {
        this.$store.dispatch('setLastNameError', '')
        return true
      }
    },

    validateAnnualIncome () {
      if (!this.isRequired(this.annualIncome)) {
        this.$store.dispatch('setAnnualIncomeError', 'This field is required.')
        return false
      } else if (!this.hasMinCurrency(this.annualIncome, 0)) {
        this.$store.dispatch('setAnnualIncomeError', 'Please enter at least $0.')
        return false
      } else if (!this.hasMaxCurrency(this.annualIncome, 9999999999999)) {
        this.$store.dispatch('setAnnualIncomeError', 'Cannot exceed $9,999,999,999,999.')
        return false
      } else if (!this.hasMaxDigits(this.annualIncome, 6)) {
        this.$store.dispatch('setAnnualIncomeError', 'Please enter at most 6 digits.')
        return false
      } else {
        this.$store.dispatch('setAnnualIncomeError', '')
        return true
      }
    },

    validateMonthlyIncome () {
      // 'required:Monthly Income|min_currency:0|max_currency:9,999,999,999,999|max_digits:5',
      if (!this.isRequired(this.monthlyIncome)) {
        this.$store.dispatch('setMonthlyIncomeError', 'This field is required.')
        return false
      } else if (!this.hasMinCurrency(this.monthlyIncome, 0)) {
        this.$store.dispatch('setMonthlyIncomeError', 'Please enter at least $0.')
        return false
      } else if (!this.hasMaxCurrency(this.monthlyIncome, 9999999999999)) {
        this.$store.dispatch('setMonthlyIncomeError', 'Cannot exceed $9,999,999,999,999.')
        return false
      } else if (!this.hasMaxDigits(this.monthlyIncome, 5)) {
        this.$store.dispatch('setMonthlyIncomeError', 'Please enter at most 5 digits.')
        return false
      } else {
        this.$store.dispatch('setMonthlyIncomeError', '')
        return true
      }
    },

    validateHourlyIncome () {
      // 'required:Hourly Wage|min_currency:0|max_currency:9,999,999,999,999|max_digits:3'
      if (!this.isRequired(this.hourlyIncome)) {
        this.$store.dispatch('setHourlyIncomeError', 'This field is required.')
        return false
      } else if (!this.hasMinCurrency(this.hourlyIncome, 0)) {
        this.$store.dispatch('setHourlyIncomeError', 'Please enter at least $0.')
        return false
      } else if (!this.hasMaxCurrency(this.hourlyIncome, 9999999999999)) {
        this.$store.dispatch('setHourlyIncomeError', 'Cannot exceed $9,999,999,999,999.')
        return false
      } else if (!this.hasMaxDigits(this.hourlyIncome, 3)) {
        this.$store.dispatch('setHourlyIncomeError', 'Please enter at most 3 digits.')
        return false
      } else {
        this.$store.dispatch('setHourlyIncomeError', '')
        return true
      }
    },

    validateHoursPerWeek () {
      // 'required:Average Hours Per Week|numeric|min_value:1|max_value:168',
      if (!this.isRequired(this.hoursPerWeek)) {
        this.$store.dispatch('setHoursPerWeekError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(this.hoursPerWeek, 1)) {
        this.$store.dispatch('setHoursPerWeekError', 'Please enter at least 1 digit.')
        return false
      } else if (!this.hasMaxCurrency(this.hoursPerWeek, 168)) {
        this.$store.dispatch('setHoursPerWeekError', 'Cannot exceed 168.')
        return false
      } else {
        this.$store.dispatch('setHoursPerWeekError', '')
        return true
      }
    },

    validateEmployerYears () {
      // max value of 99 years
      if (this.employerYears > 99) {
        this.$store.dispatch('setEmployerYearsError', 'Cannot exceed 99.')
        return false
      } else {
        return true
      }
    },

    validateEmployerMonths () {
      // max value of 11 months
      if (this.employerMonths > 11) {
        this.$store.dispatch('setEmployerMonthsError', 'Cannot exceed 11.')
        return false
      } else {
        return true
      }
    },

    validateCpost () {
      if (!this.cPostVerified) {
        this.$store.dispatch('setCPostError', true)
        return false
      } else {
        return true
      }
    },

    validateAddressFallback () {
      const streetAddress = this.fallbackData.street_address
      if (!this.isRequired(streetAddress)) {
        this.$store.dispatch('setFallBackStreetAddressError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(streetAddress, 2)) {
        this.$store.dispatch('setFallBackStreetAddressError', 'Please enter at least 2 characters.')
        return false
      } else {
        this.$store.dispatch('setFallBackStreetAddressError', '')
      }

      // validate city
      const city = this.fallbackData.city
      if (!this.isRequired(city)) {
        this.$store.dispatch('setFallBackCityError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(city, 2)) {
        this.$store.dispatch('setFallBackCityError', 'Please enter at least 2 characters.')
        return false
      } else if (!this.hasMaximumCharacters(city, 40)) {
        this.$store.dispatch('setFallBackCityError', 'Please enter at most 40 characters.')
        return false
      } else {
        this.$store.dispatch('setFallBackCityError', '')
      }

      // validate province
      const province = this.fallbackData.province

      if (!this.isRequired(province)) {
        this.$store.dispatch('setFallBackProvinceError', 'This field is required.')
        return false
      } else if (!this.hasMinimumCharacters(province, 2)) {
        this.$store.dispatch('setFallBackProvinceError', 'Please enter at least 2 characters.')
        return false
      } else if (!this.hasMaximumCharacters(province, 40)) {
        this.$store.dispatch('setFallBackProvinceError', 'Please enter at most 40 characters.')
        return false
      } else {
        this.$store.dispatch('setFallBackProvinceError', '')
      }

      // validate postal code
      const postalCode = this.fallbackData.postal_code
      if (!this.isRequired(postalCode)) {
        this.$store.dispatch('setFallBackPostalCodeError', 'This field is required.')
        return false
      } else if (!this.isValidPostalCode(postalCode)) {
        this.$store.dispatch('setFallBackPostalCodeError', 'Please enter a valid postal code.')
        return false
      } else if (!this.hasMaximumCharacters(postalCode, 20)) {
        this.$store.dispatch('setFallBackPostalCodeError', 'Please enter at most 20 characters.')
        return false
      } else {
        this.$store.dispatch('setFallBackPostalCodeError', '')
      }

      return true
    },

    validateDateOfBirth () {
      const ageOfMajority = {
        AB: 18,
        BC: 19,
        MB: 18,
        NB: 19,
        NL: 19,
        NS: 19,
        NT: 19,
        NU: 19,
        ON: 18,
        PE: 18,
        QC: 18,
        SK: 18,
        YT: 19
      }

      const province = this.$store.state.location.province

      if (!this.isRequired(this.birthData)) {
        this.$store.dispatch('setDateOfBirthError', 'This field is required.')
        return false
      } else if (!this.isValidDate(this.birthData.birth_date)) {
        this.$store.dispatch('setDateOfBirthError', 'Please enter a valid date.')
        return false
      } else if (!this.isOfAge(dayjs(this.birthData.dob_year))) {
        this.$store.dispatch('setDateOfBirthError', `Must be at least ${province ? ageOfMajority[province] : 18} years of age.`)
        return false
      } else {
        this.$store.dispatch('setDateOfBirthError', '')
        return true
      }
    },

    validatePhoneNumber () {
      if (!this.isRequired(this.homePhone)) {
        this.$store.dispatch('setPhoneNumberError', 'This field is required.')
        return false
      } else if (!this.isValidPhone(this.homePhone)) {
        this.$store.dispatch('setPhoneNumberError', 'Please enter a valid phone number.')
        return false
      } else {
        this.$store.dispatch('setPhoneNumberError', '')
        return true
      }
    },

    validatePhoneCode () {
      if (!this.isRequired(this.phoneVerificationCode)) {
        this.$store.dispatch('setPhoneCodeError', 'This field is required.')
        return false
      } else if (!this.hasMinDigits(this.phoneVerificationCode, 4)) {
        this.$store.dispatch('setPhoneCodeError', 'Please enter 4 digits.')
        return false
      } else {
        this.$store.dispatch('setPhoneCodeError', '')
        return true
      }
    },

    validateIdentityFile () {
      if (!this.isRequired(this.file)) {
        this.$store.dispatch('setIdentityFileError', 'This field is required.')
        return false
      } else if (!this.isValidFileType(this.file)) {
        this.$store.dispatch('setIdentityFileError', 'Please upload a valid file type.')
        return false
      } else if (!this.isValidFileExtension(this.file)) {
        this.$store.dispatch('setIdentityFileError', 'Please upload a valid file.')
        return false
      } else if (!this.isValidFileSize(this.file)) {
        this.$store.dispatch('setIdentityFileError', 'File size exceeds 40MB.')
        return false
      } else if (this.isFileUploading()) {
        this.$store.dispatch('setIdentityFileError', 'File is uploading.')
        return false
      } else if (!this.isFileUploadedSuccess()) {
        this.$store.dispatch('setIdentityFileError', 'File failed to upload.')
        return false
      } else if (this.id) {
        this.$store.dispatch('setIdentityFileError', '')
        return true
      }
    },

    validateAddressFile () {
      if (!this.isRequired(this.file)) {
        this.$store.dispatch('setAddressFileError', 'This field is required.')
        return false
      } else if (!this.isValidFileType(this.file)) {
        this.$store.dispatch('setAddressFileError', 'Please upload a valid file type.')
        return false
      } else if (!this.isValidFileExtension(this.file)) {
        this.$store.dispatch('setAddressFileError', 'Please upload a valid file.')
        return false
      } else if (!this.isValidFileSize(this.file)) {
        this.$store.dispatch('setAddressFileError', 'File size exceeds 40MB.')
        return false
      } else if (this.isFileUploading()) {
        this.$store.dispatch('setAddressFileError', 'File is uploading.')
        return false
      } else if (!this.isFileUploadedSuccess()) {
        this.$store.dispatch('setAddressFileError', 'File failed to upload.')
        return false
      } else if (this.poa) {
        this.$store.dispatch('setAddressFileError', '')
        return true
      }
    },

    validateIncomeFile1 () {
      if (this.file1) return true
      if (!this.isRequired(this.file)) {
        this.$store.dispatch('setIncomeFile1Error', 'This field is required.')
        return false
      } else if (!this.isValidFileType(this.file)) {
        this.$store.dispatch('setIncomeFile1Error', 'Please upload a valid file type.')
        return false
      } else if (!this.isValidFileExtension(this.file)) {
        this.$store.dispatch('setIncomeFile1Error', 'Please upload a valid file.')
        return false
      } else if (!this.isValidFileSize(this.file)) {
        this.$store.dispatch('setIncomeFile1Error', 'File size exceeds 40MB.')
        return false
      } else if (this.isFileUploading()) {
        this.$store.dispatch('setIncomeFile1Error', 'File is uploading.')
        return false
      } else if (!this.isFileUploadedSuccess()) {
        this.$store.dispatch('setIncomeFile1Error', 'File failed to upload.')
        return false
      } else if (this.proofOfIncome) {
        this.$store.dispatch('setIncomeFile1Error', '')
        return true
      }
    },

    validateIncomeFile2 () {
      if (!this.file) return false
      if (this.file2) return true
      if (!this.isValidFileType(this.file)) {
        this.$store.dispatch('setIncomeFile2Error', 'Please upload a valid file type.')
        return false
      } else if (!this.isValidFileExtension(this.file)) {
        this.$store.dispatch('setIncomeFile2Error', 'Please upload a valid file.')
        return false
      } else if (!this.isValidFileSize(this.file)) {
        this.$store.dispatch('setIncomeFile2Error', 'File size exceeds 40MB.')
        return false
      } else if (this.isFileUploading()) {
        this.$store.dispatch('setIncomeFile2Error', 'File is uploading.')
        return false
      } else if (!this.isFileUploadedSuccess()) {
        this.$store.dispatch('setIncomeFile2Error', 'File failed to upload.')
        return false
      } else if (this.proofOfIncome) {
        this.$store.dispatch('setIncomeFile2Error', '')
        return true
      }
    },

    validateHomeValue () {
      if (!this.isRequired(this.homeValue)) {
        this.$store.dispatch('setHomeValueError', 'This field is required.')
        return false
      } else if (!this.hasMinCurrency(this.homeValue, 25000)) {
        this.$store.dispatch('setHomeValueError', 'Please enter at least $25,000.')
        return false
      } else if (!this.hasMaxCurrency(this.homeValue, 9999999999999)) {
        this.$store.dispatch('setHomeValueError', 'Cannot exceed $9,999,999,999,999.')
        return false
      } else {
        this.$store.dispatch('setHomeValueError', '')
        return true
      }
    },

    validateAmountOwing () {
      // required|min_currency:0|max_currency:9,999,999,999,999"
      if (!this.isRequired(this.amountOwing)) {
        this.$store.dispatch('setAmountOwingError', 'This field is required.')
        return false
      } else if (!this.hasMinCurrency(this.amountOwing, 1)) {
        this.$store.dispatch('setAmountOwingError', 'Please enter at least $1.')
        return false
      } else if (!this.hasMaxCurrency(this.amountOwing, 9999999999999)) {
        this.$store.dispatch('setAmountOwingError', 'Cannot exceed $9,999,999,999,999.')
        return false
      } else {
        this.$store.dispatch('setAmountOwingError', '')
        return true
      }
    }

  }
}
