<template>
  <div class="app-shell">
    <AppGradientBackground />
    <!-- Hidden Fields (SID, AID, app_source, etc) -->
    <AppHiddenFields />

    <div class="app-shell__main">
      <!-- App Nav -->
      <SpringNav
        :hide-menu="hideMenu"
        :easy-logo="require('@/assets/img/nav/easy-spring-logos.svg')"
        :logo="require('@/assets/img/nav/logo.svg')"
        update-lendful-disclosure
      />

      <!-- Progress Bar -->
      <AppProgressBar
        v-if="$_progress_visible"
        :theme="theme"
        :progress-label="$_progress_label"
        :total-steps="$_progress_totalSteps"
        :current-step-index="$_progress_currentStepIndex"
      />

      <!-- Router View -->
      <AppRouterViewContainer>
        <transition
          :name="transitionName"
          mode="out-in"
          @after-enter="$_stickyCTA_afterEnter"
          @before-leave="$_stickyCTA_beforeLeave"
        >
          <router-view
            :theme="theme"
            @show-bank-skip="$_stickyCTA_showBankSkip($event)"
          />
        </transition>
      </AppRouterViewContainer>
    </div>

    <!-- <FreshChat v-if="$route.path !== '/spl-agreement/'" /> -->
    <FreshChat v-if="$route.path !== '/spl-agreement/' && lang !== 'fr'" />
    <!-- App Footer -->
    <AppFooter
      class="xs-only:pb-48"
      :theme="theme"
      :lang="lang"
    />
  </div>
</template>

<script>
import AppFooter from '@/components/layout/AppFooter'
import { SpringNav } from 'spring-ui'
import AppHiddenFields from '@/components/common/fields/AppHiddenFields'
import AppProgressBar from '@/components/common/progress/AppProgressBar'
import confirmationNumber from '@/mixins/confirmation-number'
import progress from '@/mixins/progress'
import stickyCTA from '@/mixins/sticky-cta'
import transitions from '@/mixins/transitions'
import theme from '@/mixins/theme'
import tracking from '@/mixins/tracking'
import AppGradientBackground from '@/components/common/background/AppGradientBackground'
import AppRouterViewContainer from '@/components/common/AppRouterViewContainer'
import FreshChat from '@/components/widgets/freshchat/FreshChat'

export default {
  // Name
  name: 'AppShellLoans',

  // Components
  components: {
    AppFooter,
    SpringNav,
    AppHiddenFields,
    AppProgressBar,
    AppGradientBackground,
    AppRouterViewContainer,
    FreshChat
  },

  // Mixins
  mixins: [
    confirmationNumber,
    progress,
    transitions,
    theme,
    stickyCTA,
    tracking
  ],

  // Props
  props: {
    module: {
      type: String,
      default: ''
    },

    canonicalModule: {
      type: String,
      default: ''
    },

    hideMenu: {
      type: Boolean,
      default: false
    },

    lang: {
      type: String,
      default: 'en'
    }
  },

  head () {
    return {
      link: [
        { rel: 'canonical', href: this.$_tracking_canonicalURL(this.canonicalModule || this.module) }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/scss/step-transitions';

.app-shell {
  @apply overflow-x-hidden relative flex flex-col;

  &__main {
    @apply pt-80 min-h-[100vh];

    @screen lg {
      @apply pt-100;
    }
  }
}
</style>
