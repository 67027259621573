import AppShellLoans from '@/components/application/AppShellLoans'
import preventRefresh from '@/router/utils/prevent-refresh'
import nextRoute from '@/router/utils/next-route'
import nextEmploymentRoute from '@/router/utils/next-employment-route'
import store from '@/store'
import * as ROUTE_PATHS from '@/constants/route-paths'
import * as PROGRESS_LABELS from '@/constants/progress-label'
import * as STEP_LABELS from '@/constants/step-label'
import Cookies from '@/utilities/cookies'

const Email = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/Email'
)
const Name = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/Name'
)

const LoanAmount = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/personal-loans/LoanAmount'
)
const CreditRating = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/personal-loans/CreditRating'
)
const HomeAddress = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/HomeAddress'
)
const DateOfBirth = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/DateOfBirth'
)

const EmploymentStatus = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/EmploymentStatus'
)

const EmploymentStatusInsurance = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/EmploymentStatusInsurance'
)

const EmploymentStatusOther = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/EmploymentStatusOther'
)

const IncomeDetailsFrequency = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/IncomeDetailsFrequency'
)

const IncomeDetailsAmount = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/IncomeDetailsAmount'
)

const IncomeDuration = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/IncomeDuration'
)

const ContactPhone = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/ContactPhone'
)

const ContactCode = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/ContactCode'
)

const Sorry = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/Sorry'
)

const VerifyIdentity = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/document-upload/VerifyIdentity'
)

const VerifyIncome = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/document-upload/VerifyIncome'
)

const VerifyAddress = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/document-upload/VerifyAddress'
)

const CompleteSkippedSteps = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/CompleteSkippedSteps'
)

const WelcomeBack = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/personal-loans/WelcomeBack'
)

const VerifyBank = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/VerifyBank'
)

const WaitScreen = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/wait-screen/WaitScreen'
)

const FinancialSelector = () => import(
  /* webpackChunkName: "personal-loans-v1" */
  '@/components/steps/common/FinancialSelector'

)

const ApplicationSuccessfulVariant = () => import(
  /* webpackChunkName: "personal-loans-v2" */
  '@/components/steps/personal-loans/ApplicationSuccessfulVariant'
)

/**
 * AppName / Variation
 * Used to build the app_source field
 */
const appName = 'springloan-usl'
const variation = 'v1'
const basePath = '/personal-loans'
const leadType = 'Personal Loan'

const nonDocStepSettings = {
  showFooter: true,
  showAppButton: true,
  showSkipStep: false
}

const props = {
  basePath
}

const verificationRoutes = [
  {
    path: ROUTE_PATHS.VERIFY_BANK,
    verified: () => store.state.documents.bankStatements,
    enabled: () => process.env.VUE_APP_FLINKS_ENABLED
  }, {
    path: ROUTE_PATHS.VERIFY_IDENTITY,
    verified: () => store.state.documents.id,
    enabled: () => true
  }, {
    path: ROUTE_PATHS.VERIFY_ADDRESS,
    verified: () => store.state.documents.poa,
    enabled: () => store.getters.collectAllPLDocs && store.state.documents.poaRequired
  }, {
    path: ROUTE_PATHS.VERIFY_INCOME,
    verified: () => store.state.documents.proofOfIncome,
    enabled: () => store.getters.collectAllPLDocs && store.state.documents.poiRequired
  }, {
    path: ROUTE_PATHS.COMPLETE_SKIPPED_STEPS,
    verified: () => store.state.application.leadSubmitted,
    enabled: () => true
  }
]

const routes = [
  {
    path: basePath,
    component: AppShellLoans,
    meta: {
      basePath,
      appName,
      variation,
      leadType
    },
    props: {
      theme: 'blue',
      module: 'personal-loans'
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/foundation/date-of-birth' && to.path === '/personal-loans/contact-phone') {
        return next({ query: { ...to.query } })
      }

      preventRefresh(to, from, next, basePath, [])
      store.dispatch('setSubmitIfReady', true)
    },
    children: [
      {
        beforeEnter: async (to, from, next) => {
          await Cookies.autoFillCookieData('sf-ld-data', to.path, 'ploan')
          return next()
        },
        path: '',
        component: Email,
        meta: {
          index: 1,
          nextStep: ROUTE_PATHS.NAME,
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          title: 'Apply in 3 Minutes and Get Up to $35,000 Transferred Today',
          ...props
        }
      }, {
        path: ROUTE_PATHS.NAME,
        component: Name,
        name: 'Name',
        meta: {
          index: 2,
          nextStep: () => {
            const returningCustomerSubmitted = store.state.application.returningCustomerSubmitted
            const returningCustomerInProgress = store.state.returningCustomer.is_in_progress
            if (returningCustomerSubmitted) {
              return ROUTE_PATHS.APPLICATION_SUCCESS
            } else if (returningCustomerInProgress) {
              return ROUTE_PATHS.WELCOME_BACK
            } else {
              return ROUTE_PATHS.LOAN_AMOUNT
            }
          },
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.WELCOME_BACK,
        component: WelcomeBack,
        meta: {
          index: 2,
          progress: false,
          showFooter: true,
          showAppButton: true,
          showSkipStep: false,
          nextStep: () => {
            return store.getters.getNextStepForReturningCustomer
          }
        },
        props: {
          stepLabels: () => {
            const labels = [
              STEP_LABELS.PERSONAL,
              STEP_LABELS.BANK,
              STEP_LABELS.IDENTITY
            ]

            if (store.getters.collectAllPLDocs) {
              labels.push(
                STEP_LABELS.INCOME,
                STEP_LABELS.ADDRESS
              )
            }

            return labels
          },
          ...props
        }
      }, {
        path: ROUTE_PATHS.LOAN_AMOUNT,
        component: LoanAmount,
        meta: {
          index: 3,
          nextStep: ROUTE_PATHS.CREDIT_RATING,
          progressLabel: PROGRESS_LABELS.THREE_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.CREDIT_RATING,
        component: CreditRating,
        meta: {
          index: 4,
          nextStep: ROUTE_PATHS.EMPLOYMENT_STATUS,
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS,
        component: EmploymentStatus,
        meta: {
          index: 5,
          nextStep: () => nextEmploymentRoute(),
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS_INSURANCE,
        component: EmploymentStatusInsurance,
        meta: {
          index: 6,
          nextStep: () => {
            return nextEmploymentRoute()
          },
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.EMPLOYMENT_STATUS_OTHER,
        component: EmploymentStatusOther,
        meta: {
          index: 7,
          nextStep: () => {
            return nextEmploymentRoute()
          },
          progressLabel: PROGRESS_LABELS.TWO_TO_THREE_MINUTES,
          ...nonDocStepSettings
        }
      }, {
        path: ROUTE_PATHS.INCOME_DETAILS_FREQUENCY,
        component: IncomeDetailsFrequency,
        meta: {
          index: 8,
          nextStep: ROUTE_PATHS.INCOME_DETAILS_AMOUNT,
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.INCOME_DETAILS_AMOUNT,
        component: IncomeDetailsAmount,
        meta: {
          index: 9,
          nextStep: ROUTE_PATHS.INCOME_DURATION,
          progressLabel: PROGRESS_LABELS.TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.INCOME_DURATION,
        component: IncomeDuration,
        meta: {
          index: 10,
          nextStep: ROUTE_PATHS.HOME_ADDRESS,
          progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.HOME_ADDRESS,
        component: HomeAddress,
        meta: {
          index: 11,
          nextStep: ROUTE_PATHS.DATE_OF_BIRTH,
          progressLabel: PROGRESS_LABELS.ONE_TO_TWO_MINUTES,
          ...nonDocStepSettings
        },
        props: {
          searchVersion: process.env.VUE_APP_CPOST_SEARCH_VERSION,
          ...props
        }
      }, {
        path: ROUTE_PATHS.DATE_OF_BIRTH,
        component: DateOfBirth,
        meta: {
          index: 12,
          nextStep: ROUTE_PATHS.CONTACT_PHONE,
          progressLabel: PROGRESS_LABELS.ONE_MINUTE,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.CONTACT_PHONE,
        component: ContactPhone,
        meta: {
          index: 13,
          nextStep: () => {
            const twilioFailed = store.state.application.twilioFailed
            const leadSubmitted = store.state.application.leadSubmitted
            if (leadSubmitted) return ROUTE_PATHS.APPLICATION_SUCCESS
            const nextRoute = process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATHS.VERIFY_BANK : ROUTE_PATHS.FINANCIAL_SELECTOR
            return twilioFailed ? nextRoute : ROUTE_PATHS.CONTACT_CODE
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.CONTACT_CODE,
        component: ContactCode,
        meta: {
          index: 14,
          nextStep: () => {
            return process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATHS.VERIFY_BANK : ROUTE_PATHS.FINANCIAL_SELECTOR
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          ...nonDocStepSettings
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.FINANCIAL_SELECTOR,
        component: FinancialSelector,
        meta: {
          index: 14,
          nextStep: ROUTE_PATHS.VERIFY_BANK,
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: false
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.VERIFY_BANK,
        component: VerifyBank,
        meta: {
          index: 15,
          nextStep: () => {
            return nextRoute(verificationRoutes, ROUTE_PATHS.APPLICATION_SUCCESS, ROUTE_PATHS.VERIFY_BANK)
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: false,
          showSkipStep: false,
          showBankSkipStep: false,
          skipStepProp: '',
          bankSkipBasic: true,
          hideChatButton: true
        },
        props: {
          title: 'Verify Your Income And Get Approved Today!',
          subtitle: 'Get the best rate by connecting your bank account. We will not store your login information.',
          resetDocsOnLoad: true,
          ...props
        }
      }, {
        path: ROUTE_PATHS.VERIFY_IDENTITY,
        component: VerifyIdentity,
        meta: {
          index: 16,
          nextStep: () => {
            const eligibilityStatus = store.state.customer.eligibilityStatus
            const submitted = store.state.application.leadSubmitted
            if (eligibilityStatus === 'Easy') {
              if (store.state.documents.poaRequired) {
                return ROUTE_PATHS.VERIFY_ADDRESS
              } else if (store.state.documents.poiRequired) {
                return ROUTE_PATHS.VERIFY_INCOME
              } else {
                return ROUTE_PATHS.APPLICATION_SUCCESS
              }
            }
            if (submitted) return ROUTE_PATHS.APPLICATION_SUCCESS
            return ROUTE_PATHS.ANALYZING_APPLICATION
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: true,
          showAppButton: true,
          showSkipStep: false,
          skipStepProp: 'ID'
        },
        props: {
          ...props
        }
      },
      {
        path: ROUTE_PATHS.ANALYZING_APPLICATION,
        component: WaitScreen,
        meta: {
          index: 17,
          progress: false,
          disableGridLayout: true,
          nextStep: () => {
            const eligibilityStatus = store.state.customer.eligibilityStatus
            if (eligibilityStatus === 'Easy') {
              if (store.state.documents.poaRequired) {
                return ROUTE_PATHS.VERIFY_ADDRESS
              } else if (store.state.documents.poiRequired) {
                return ROUTE_PATHS.VERIFY_INCOME
              } else {
                return ROUTE_PATHS.APPLICATION_SUCCESS
              }
            }
            return ROUTE_PATHS.APPLICATION_SUCCESS
          }
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.VERIFY_ADDRESS,
        component: VerifyAddress,
        meta: {
          index: 18,
          nextStep: () => {
            return nextRoute(verificationRoutes, ROUTE_PATHS.APPLICATION_SUCCESS, ROUTE_PATHS.VERIFY_ADDRESS)
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'address'
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.VERIFY_INCOME,
        component: VerifyIncome,
        meta: {
          index: 19,
          nextStep: () => {
            return nextRoute(verificationRoutes, ROUTE_PATHS.APPLICATION_SUCCESS, ROUTE_PATHS.VERIFY_INCOME)
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          showFooter: true,
          showAppButton: true,
          showSkipStep: true,
          skipStepProp: 'paystubs'
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.COMPLETE_SKIPPED_STEPS,
        name: `pl-${ROUTE_PATHS.COMPLETE_SKIPPED_STEPS}`,
        component: CompleteSkippedSteps,
        meta: {
          index: 20,
          nextStep: () => {
            const submitted = store.state.application.leadSubmitted

            return submitted
              ? ROUTE_PATHS.APPLICATION_SUCCESS
              : nextRoute(verificationRoutes, ROUTE_PATHS.APPLICATION_SUCCESS)
          },
          progressLabel: PROGRESS_LABELS.LESS_THAN_ONE_MINUTE,
          ...nonDocStepSettings,
          showSubmitNowButton: true,
          showContinueByText: true,
          continueText: 'Take Me Back',
          submitIfReady: true
        },
        props: {
          stepLabels: () => {
            const labels = [
              STEP_LABELS.PERSONAL,
              STEP_LABELS.BANK,
              STEP_LABELS.IDENTITY
            ]

            if (store.getters.collectAllPLDocs) {
              labels.push(
                STEP_LABELS.INCOME,
                STEP_LABELS.ADDRESS
              )
            }

            return labels
          },
          ...props
        }
      }, {
        path: ROUTE_PATHS.APPLICATION_SUCCESS,
        name: `pl-${ROUTE_PATHS.APPLICATION_SUCCESS}`,
        component: ApplicationSuccessfulVariant,
        meta: {
          index: 21,
          progressLabel: PROGRESS_LABELS.FINISHED,
          showFooter: false,
          showSkipStep: false,
          showContinueByText: true
        },
        props: {
          ...props
        }
      }, {
        path: ROUTE_PATHS.SORRY,
        component: Sorry,
        meta: {
          index: 22,
          progress: false,
          showFooter: false,
          showSkipStep: false,
          disableGridLayout: true
        },
        props: {
          ...props
        }
      }
    ]
  },

  /**
   * Fallback Route
   * Redirects back to the basePath
   */
  {
    path: `${basePath}/*`,
    redirect: basePath
  }
]

export default routes
