import Axios from 'axios'
import navigation from '@/mixins/navigation'
import submit from '@/mixins/submit'
import DataLayer from '@/utilities/data-layer'
import * as ROUTE_PATHS from '../../constants/route-paths'
import axiosRetry from 'axios-retry'
import LocalStorage from '@/utilities/localStorage'
import SlackNotification from '../../services/SlackNotification'

export default {
  mixins: [navigation, submit],

  data () {
    return {
      personalLoansRoute: process.env.VUE_APP_LEAD_API_PERSONAL_LOANS_ROUTE,
      migrationFlag: process.env.VUE_APP_MIGRATION_FLAG
    }
  },

  props: {
    basePath: {
      type: String,
      validator (value) {
        return ['/personal-loans', '/personal-loans-v2', '/personal-loans-v3', '/personal-loans-v4', '/personal-loans-v5', '/personal-loans-v6', '/foundation'].includes(value)
      },
      default: '/personal-loans'
    }
  },

  computed: {

    email () {
      return this.$store.state.customer.email
    },

    $_loans_url () {
      return `${this.leadAPIUrl}${this.personalLoansRoute}`
    },

    $_loans_createLeadData () {
      return this.$store.getters['loans/leadCreationData']
    },

    $_loans_submitLeadData () {
      return this.$store.getters['loans/leadSubmissionData']
    },

    $_loans_isReadyToSubmit () {
      return this.$store.getters.isReadyToSubmit
    },

    $_loans_updateUrl () {
      const cduid = this.$store.state.application.cduid
      return `${this.$_loans_url}/${cduid}`
    },

    $_loans_trackingData () {
      return this.$store.getters['loans/trackingData']
    },

    $_loans_dataLayerObject () {
      return {
        event: `spring_${this.eventPrefix}_submit_application_continue`
      }
    },

    $_loans_checkPartnerUrl () {
      return `${this.leadAPIUrl}partner-pii/`
    },

    $_loans_submittedDocs_dlObj () {
      const docsUploadedDataLayerString = this.$store.getters.docsUploadedDataLayerString
      const docsUploadCount = this.$store.getters.docsUploadCount
      const returningCustomer = this.$store.state.returningCustomer.is_in_progress
      const submittedDocs = `submitted_docs_${docsUploadedDataLayerString}`
      return returningCustomer
        ? {
          event: `spring_${this.eventPrefix}_returning_app_finished_docs_submitted`,
          returning_submitted_docs: docsUploadCount,
          returning_docs_type: submittedDocs
        }
        : {
          event: `spring_${this.eventPrefix}_app_finished_docs_submitted`,
          submitted_docs: docsUploadCount,
          docs_type: submittedDocs
        }
    },

    $_is_foundation () {
      return this.$store.getters['loans/isFoundation']
    },

    isPersonalLoan () {
      return this.$store.getters['loans/isPersonalLoan']
    },

    leadType () {
      return this.$route.matched[0].meta.leadType
    },

    isDeeperPrepop () {
      return this.$store.state.application.isDeeperPrepop
    },

    isPrepop () {
      return this.$store.state.application.isPrepop
    },

    prePopUid () {
      return this.$route.query.id
    }
  },

  methods: {
    $_loans_returningCustomerFormData (id) {
      const trackingData = this.$_loans_trackingData
      const crm = this.$store.state.account.crm
      const formData = new FormData()
      crm === 'sf' ? formData.append('salesforce_id', id) : formData.append('access_id', id)
      for (const key in trackingData) {
        const value = trackingData[key] !== undefined ? trackingData[key] : ''
        formData.append(key, value)
      }
      return formData
    },

    $_loans_createLeadFormData (data) {
      const leadData = { ...data }
      const formData = new FormData()

      for (const key in leadData) {
        const value = leadData[key] !== undefined ? leadData[key] : ''
        formData.append(key, value)
      }
      return formData
    },

    /**
     * Create Personal Loans Lead
     * @desc submits the lead data to leadApi to create a lead.
     * If it fails, call the resubmit function
     */
    async $_loans_createLead (dataLayerObject, crmID = null, enableInProgress = true) {
      if (this.submitting) return false
      if (this.$_submit_leadCreated) return true
      this.submitting = true
      const data = this.$_loans_createLeadPayload(crmID)

      try {
        const isValid = await this.$_navigation_validateStep()
        if (!isValid) throw new Error('Validation failed.')

        const response = await Axios({
          method: 'POST',
          url: this.$_loans_url,
          data: data
        })

        const responseData = response.data.data
        const {
          uid,
          is_in_progress: isInProgress,
          is_submitted: isSubmitted,
          redirect_pii: isExpired,
          banking_provider: bankingProvider,
          banking_splitter: bankingSplitter,
          credit_screen: creditScreen,
          partner_ref_id: partnerRefId,
          crm,
          maintenance_mode: maintenanceMode,
          /* eslint-disable camelcase */
          Lead_Type__c: lead_type,
          Loan_product__c: foundation_selected_plan
        } = responseData

        if ((crm === 'sf' && this.migrationFlag) || maintenanceMode) return this.$router.push({ path: '/maintenance' })
        if (creditScreen) this.$store.dispatch('setCreditScreen', creditScreen)
        if (partnerRefId) this.$store.dispatch('setPartnerRefId', partnerRefId)
        if (isExpired) {
          this.$_loans_redirectPii(responseData)
          return
        }
        if (!uid) throw new Error('uid undefined')
        this.$store.dispatch('setLeadCreated', true)
        this.$store.dispatch('setAppCDUID', uid)

        window.rudderanalytics.identify(this.email, {
          cduid: uid
        })

        // if we are not using the FI selector, set the banking provider
        // else set the master banking provider for the FI Selector Screen
        if (process.env.VUE_APP_DISABLE_FI_SELECTOR) {
          this.$store.dispatch('setBankingProvider', bankingProvider)
        } else {
          this.$store.dispatch('setMasterBankingProvider', bankingProvider)
          this.$store.dispatch('setBankingSplitter', bankingSplitter)
        }
        // date.now() will act as sessionId
        this.$store.dispatch('setSessionId', Date.now())
        if (lead_type === 'The Foundation') {
          if (foundation_selected_plan === 'Foundation Basic' || foundation_selected_plan === 'Foundation Basic plus') this.$store.dispatch('setIsFoundationGold', false)
        }
        if (isSubmitted) {
          this.$_loans_returningCustomerHandleSubmitted()
          return
        } else if (enableInProgress && isInProgress) {
          // Handle Returning Customer Partially Finished Here
          await this.$_loans_returningCustomerHandleInProgress(responseData)
          return
        }

        if (this.isPersonalLoan) window.rudderanalytics.track(`spring_ploan_register_account_continue`)

        this.$_navigation_handleNext(dataLayerObject)
      } catch (error) {
        console.warn(error)
        if (!crmID) {
          const data = {
            'Last_Name': this.$_loans_createLeadData['last_name'],
            'First_Name': this.$_loans_createLeadData['first_name'],
            'Error': `${error.message}, Response Code ${error.response?.status}`,
            'Email': this.$_loans_createLeadData['email'],
            'URL': window.location.href
          }
          SlackNotification.send(process.env.VUE_APP_SLACK_LEAD_FAIL_WEBHOOK_URL, data)
        }
        throw error
      } finally {
        this.submitting = false
      }
    },

    async $_loans_createQuizLead () {
      if (this.submitting) return false
      if (this.$_submit_leadCreated) return true
      this.submitting = true
      const data = this.$_loans_createLeadPayload()

      try {
        const isValid = await this.$_navigation_validateStep()
        if (!isValid) return

        const response = await Axios({
          method: 'POST',
          url: this.$_loans_url,
          data: data
        })

        const responseData = response.data.data
        const {
          uid,
          is_in_progress: isInProgress,
          is_submitted: isSubmitted,
          banking_provider: bankingProvider,
          credit_screen: creditScreen,
          partner_ref_id: partnerRefId
        } = responseData

        if (creditScreen) this.$store.dispatch('setCreditScreen', creditScreen)
        if (partnerRefId) this.$store.dispatch('setPartnerRefId', partnerRefId)

        if (!uid) throw new Error('uid undefined')
        this.$store.dispatch('setLeadCreated', true)
        this.$store.dispatch('setAppCDUID', uid)
        this.$store.dispatch('setMasterBankingProvider', bankingProvider)
        // date.now() will act as sessionId
        this.$store.dispatch('setSessionId', Date.now())

        if (isSubmitted) {
          this.$store.dispatch('setReturningCustomerSubmitted', true)
        } else if (isInProgress) {
          this.$store.dispatch('setReturningCustomer', responseData)
        }
        return true
      } catch (error) {
        console.warn(error)
      } finally {
        this.submitting = false
      }
    },

    /**
     * Submit Personal Loans Lead
     * @desc submits the remaining lead data to leadApi.
     */
    async $_loans_submitLead () {
      if (this.submitting) return false
      if (this.$_submit_leadSubmitted) return true
      this.submitting = true
      const data = this.$_loans_submitLeadData
      try {
        const isValid = await this.$_navigation_validateStep()
        if (!isValid) throw new Error('Validation failed.')

        if (this.leadType === 'Personal Loan') {
          await Axios({
            method: 'PATCH',
            url: `${this.leadAPIUrl}complete-lead/${data.cduid}`
          })
        } else {
          await Axios({
            method: 'PATCH',
            url: `${this.leadAPIUrl}submit/${data.cduid}`
          })
        }
        this.$store.dispatch('setLeadSubmitted', true)
        return true
      } catch (error) {
        console.warn(error)
        throw error
      } finally {
        this.submitting = false
      }
    },
    // Handler to make handle multiple clicks
    async $_loans_submitLeadIfReady (forceSubmit = false, skipStep = false, dataLayerObject) {
      if (!this.$_submit_leadSubmitted) {
        // If all documents are collected we can submit
        // OR We are submitting from a non-verify page
        if (this.$_loans_isReadyToSubmit || forceSubmit) {
          const submitSuccess = await this.$_loans_submitLead()
          // If application is successfully submitted
          // Go To Application Complete page
          if (submitSuccess) {
            if (dataLayerObject) DataLayer.pushObject(dataLayerObject)
            DataLayer.pushObject(this.$_loans_submittedDocs_dlObj)
            if (this.eventPrefix === 'ploan') {
              window.rudderanalytics.track('spring_ploan_app_finished_docs_submitted', {
                submitted_docs: this.$store.getters.docsUploadCount
              })
            }
            this.$_navigation_handleNext(this.$_loans_dataLayerObject)
          }
        } else {
          this.$_navigation_handleNext(dataLayerObject, skipStep)
        }
      } else {
        // If they Navigate Back via Browser,
        // Confirm with BA how to handle in future tickets
      }
    },

    async $_loans_updateLead (data, dataLayerObject) {
      if (this.submitting) return false
      if (this.$_submit_leadSubmitted) return this.$_navigation_handleNext(dataLayerObject)

      let response
      this.submitting = true
      try {
        axiosRetry(Axios, { retries: 3,
          retryCondition: (e) => {
            // pages contact-code and contact-phone are not retryable
            if (this.$route.path.includes('contact-code') || this.$route.path.includes('contact-phone')) return false
            return true
          } })
        response = await Axios({
          method: 'PATCH',
          url: this.$_loans_updateUrl,
          data: data
        })
      } catch (error) {
        console.warn(error)
        DataLayer.pushObject({ event: `spring_${this.eventPrefix}_lead_update_failure` })
      } finally {
        this.submitting = false
      }

      const creditScreen = response?.data?.data?.['credit_screen']
      if (creditScreen) this.$store.dispatch('setCreditScreen', creditScreen)

      const partnerRefId = response?.data?.data?.['partner_ref_id']
      if (partnerRefId) this.$store.dispatch('setPartnerRefId', partnerRefId)

      const fdnEligible = response?.data?.data?.['fdn_eligible']

      if (this.$_is_foundation && fdnEligible === false) {
        this.handleScreenTimeDataLayer()
        this.$router.push({
          path: `${this.basePath}/sorry`
        })
      } else {
        this.$_navigation_handleNext(dataLayerObject)
      }

      return response
    },

    $_loans_updateDocumentStore (data) {
      const {
        photo_id: photoId,
        proof_of_income: proofOfIncome,
        proof_of_address: proofOfAddress,
        flinks,
        poa_required: poaRequired,
        poi_required: poiRequired
      } = data

      this.$store.dispatch('setId', photoId)
      this.$store.dispatch('setProofOfIncome', proofOfIncome)
      this.$store.dispatch('setPOA', proofOfAddress)
      this.$store.dispatch('setBankStatements', flinks)
      if (flinks) {
        this.$store.dispatch('setSmartAppProceed', true)
      }
      if (poaRequired === false) this.$store.dispatch('setPOARequired', false)
      if (poiRequired === false) this.$store.dispatch('setPOIRequired', false)
    },

    $_loans_updateCustomerStore (data) {
      const {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        interest_rate: interestRate,
        loan_duration: loanDuration,
        approval_amount: approvalAmount
      } = data

      if (!this.$store.state.customer.first_name) this.$store.dispatch('setFirstName', firstName)
      if (!this.$store.state.customer.last_name) this.$store.dispatch('setLastName', lastName)
      if (!this.$store.state.customer.email) this.$store.dispatch('setEmail', email)
      if (!this.$store.state.customer.home_phone && phone) this.$store.dispatch('setHomePhone', phone)
      // Set loan parameters received from clearscore
      if (!this.$store.state.customer.interest_rate) this.$store.commit('SET_CLEAR_SCORE_LOAN_DATA', { interest_rate: interestRate, loan_duration: loanDuration, approval_amount: approvalAmount })
    },

    $_loans_createLeadPayload (crmID) {
      const data = crmID
        ? this.$_loans_returningCustomerFormData(crmID)
        : this.$_loans_createLeadFormData(this.$_loans_createLeadData)

      const sid = this.$route.query?.SID

      if (sid?.toLowerCase() === 'loanscanada' || sid?.toLowerCase() === 'loanscanadabank') {
        data.append('lc_id', this.$route.query.AID || '')
      }

      if (this.isDeeperPrepop) {
        data.append('Twilio_Phone_Verified__c', true)
        data.append('pre_pop_uid', this.prePopUid)
        data.append('employer_years', 1)
        data.append('employer_months', 0)
      } else if (this.isPrepop) {
        data.append('pre_pop_uid', this.prePopUid)
      }

      return data
    },

    async $_loans_redirectPii (responseData) {
      const {
        FirstName: firstName,
        phone,
        isDeeperPrePop: deeperPrepop,
        isLead,
        partnerName,
        interest_rate: interestRate,
        loan_duration: loanDuration,
        approval_amount: approvalAmount
      } = responseData

      if (firstName) this.$_loans_updateCustomerStore(responseData)

      if (isLead) {
        const email = responseData.Email
        window.rudderanalytics.identify(email, {
          email: email
        })
        DataLayer.pushObject({ event: 'spring_ploan_prepop_existing' })
        window.rudderanalytics.track('spring_ploan_prepop_existing')
        this.$_loans_createLead()

        return
      } else if (this.isPrepop) {
        DataLayer.pushObject({ event: 'spring_ploan_prepop_new' })
        window.rudderanalytics.track(`spring_ploan_prepop_new`)
      }

      if (deeperPrepop && phone) {
        this.$store.dispatch('setAffiliateName', partnerName)
        this.$store.dispatch('setIsDeeperPrepop', true)
        this.$store.dispatch('setHomePhone', phone)
        // If we have all the required fields set isDeeperPrepopPrime to true
        if (interestRate && loanDuration && approvalAmount) this.$store.dispatch('setIsDeeperPrepopPrime', true)

        try {
          // call lambda middleware to check if phone number
          // is valid and can receive a verificationSid
          await this.$store.dispatch('requestVerification', {
            phone: phone.replace(/[^0-9]/g, ''),
            channel: 'sms'
          })
        } catch (error) {
          console.warn(error)
          // Redirect to PII
          this.$router.push({
            path: this.basePath,
            query: { ...this.$route.query }
          })
        }
        this.$router.push({
          path: '/personal-loans/contact-code',
          query: { ...this.$route.query }
        })
      } else {
        // Redirect to PII
        this.$router.push({
          path: this.basePath,
          query: { ...this.$route.query }
        })
      }
    },

    $_loans_returningCustomerHandleSubmitted () {
      this.$store.dispatch('setReturningCustomerSubmitted', true)
      DataLayer.pushObject({ event: `spring_${this.eventPrefix}_returning_customer_account_sign_in_submitted` })
      if (this.eventPrefix === 'ploan') {
        window.rudderanalytics.track(`spring_ploan_returning_customer_account_sign_in_submitted`)
      }
      // Redirect to Application Successful
      this.handleScreenTimeDataLayer()
      this.$router.push({
        path: `${this.basePath}/application-success`,
        query: { ...this.$route.query }
      })
    },

    async $_loans_returningCustomerHandleInProgress (responseData) {
      // FE Override fo Bank Provider
      // Force user to go through FI Selector again
      const bankProvider = this.$route.query.bankingProvider

      if (bankProvider) responseData.flinks = false

      const {
        FirstName: firstName,
        out_of_province: outOfProvince,
        Lead_Type__c: leadType,
        Product_Eligibility__c: productEligibility,
        Payment_Frequency__c: paymentFrequency,
        First_Payment_Date__c: paymentDate,
        flinks,
        photo_id: photoId,
        proof_of_income: proofOfIncome,
        proof_of_address: proofOfAddress,
        Agreement_Accepted__c: agreementAccepted,
        Loan_Product__c: loanProduct,
        Harvest_Page_Landed__c: harvestPageLanded,
        bloom_flow: bloomFlow,
        skipPLDecline
      } = responseData

      const dataLayerContinue = {
        event: `spring_${this.eventPrefix}_returning_customer_account_sign_in_continue`
      }
      let path
      const query = { ...this.$route.query }

      if (skipPLDecline) return this.$router.push({ path: `${this.basePath}/${ROUTE_PATHS.APPLICATION_SUCCESS}` })
      const fdnEligible = responseData['fdn_eligible']
      if (this.$_is_foundation && fdnEligible === false) {
        this.handleScreenTimeDataLayer()
        return this.$router.push({
          path: `${this.basePath}/sorry`
        })
      }
      if (outOfProvince === true) {
        this.$store.dispatch('setFirstName', firstName)
        this.$store.dispatch('setCustomerOutOfProvince', outOfProvince)
        this.handleScreenTimeDataLayer()
        this.$router.push({
          path: `${this.basePath}/sorry`
        })
      } else {
        // Set States
        this.$store.dispatch('setReturningCustomer', responseData)
        this.$store.dispatch('setCustomerEligibility', productEligibility)
        this.$store.dispatch('setPaymentFrequency', paymentFrequency)
        this.$store.dispatch('setPaymentDate', paymentDate)
        this.$_loans_updateDocumentStore(responseData)
        this.$_loans_updateCustomerStore(responseData)

        LocalStorage.removeItem('partner_pii')

        if (leadType === 'Declined PL - Foundation') {
          if (loanProduct === 'Foundation Basic' || loanProduct === 'Foundation Basic plus') this.$store.dispatch('setIsFoundationGold', false)
          await this.$store.dispatch('setCustomerEligibility', 'Foundation')
          // If user has already uploaded all required docs
          // but dropped off.
          this.$store.dispatch('setIsBloomFlow', bloomFlow)
          if (agreementAccepted) {
            path = `${ROUTE_PATHS.PL_DECLINED}/${ROUTE_PATHS.APPLICATION_SUCCESS}`
          } else if (!harvestPageLanded) {
            path = `${ROUTE_PATHS.PL_DECLINED}`
          } else {
            path = `${ROUTE_PATHS.PL_DECLINED}/${ROUTE_PATHS.WELCOME_BACK}`
            if (this.eventPrefix === 'ploan') {
              window.rudderanalytics.track('spring_ploan_returning_customer_account_sign_in_continue')
            }
            DataLayer.pushObject(dataLayerContinue)
          }
        } else if (leadType === 'The Foundation') { // if its a returning cx for foundation flow
          if (loanProduct === 'Foundation Basic' || loanProduct === 'Foundation Basic plus') this.$store.dispatch('setIsFoundationGold', false)
          path = `${this.basePath}/${ROUTE_PATHS.RETURNING_CUSTOMER_PAGE}`
        } else {
          // Edge Case For When User Uploads All Docs
          // But Drops Off and then Returns
          // Submit Lead
          if (this.$_loans_isReadyToSubmit) {
            await this.$_loans_submitLead()
            path = `${this.basePath}/${ROUTE_PATHS.APPLICATION_SUCCESS}`
          } else {
            // if flinks and photoid was done and proof of income and proof of address was not done
            // assume user dropped off during delay and redirect to delay screen
            if (flinks && photoId && !proofOfIncome && !proofOfAddress) {
              path = `${this.basePath}/${ROUTE_PATHS.ANALYZING_APPLICATION}`
            } else {
              path = `${this.basePath}/${ROUTE_PATHS.WELCOME_BACK}`
            }
            DataLayer.pushObject(dataLayerContinue)
          }
        }
        this.handleScreenTimeDataLayer()
        this.$router.push({ path, query })
      }
    },

    async $_loans_checkPartnerId (partnerId, trackingParams) {
      try {
        const response = await Axios({
          method: 'POST',
          url: `${this.$_loans_checkPartnerUrl}${partnerId}`,
          data: trackingParams
        })
        const piiData = response.data.data
        if (piiData.crm === 'sf' && this.migrationFlag) return this.$router.push({ path: '/maintenance' })
        await LocalStorage.setItem('partner_pii', piiData)
        this.$store.dispatch('setIsPrepop', true)
        this.$_loans_redirectPii(piiData)
      } catch (error) {
        console.warn(error)
        this.$router.push({
          path: '/personal-loans',
          query: { ...this.$route.query }
        })
      }
    }
  }
}
