import Vue from 'vue'
import VueRouter from 'vue-router'
import PersonalLoansV1 from './personal-loans/v1'
import HomeEquityLoansV1 from './home-equity-mortgage/v1'
import NewMortgageV1 from './new-mortgage/v1'
import RefinanceMortgageV1 from './refinance-mortgage/v1'
import FoundationV1 from './foundation/v1'
import FoundationDeclinedV1 from './foundation-declined-pl/v1'
import DocumentsV1 from './documents/v1'
import MortgagesV2 from './mortgages/v2'
import FoundationGradV1 from './foundation-grad/v1'
import FoundationGradQualifiedV1 from './foundation-grad-qualified/v1'
import EvergreenAgreementV1 from './evergreen-agreement/v1'
import EvergreenAgreementBankingV1 from './evergreen-agreement-banking/v1'
import EvergreenV1 from './evergreen/v1'
import FoundationAgreementV1 from './foundation-agreement/v1'
import SPLAgreementV1 from './spl-agreement/v1'
import QuizV1 from './quiz/v1'
import store from '@/store'

const devMode = process.env.NODE_ENV !== 'production'
const minisiteUrl = process.env.VUE_APP_MINISITE_URL
const defaultDevRoute = '/personal-loans'

Vue.use(VueRouter)

const routes = [
  /**
   * TODO: Find out what /application should redirect to...
   */
  {
    path: '/',
    beforeEnter: () => {
      window.location.href = devMode ? defaultDevRoute : minisiteUrl
    }
  },
  {
    path: '*',
    beforeEnter: () => {
      window.location.href = devMode ? defaultDevRoute : minisiteUrl
    }
  },
  {
    path: '/error',
    component: () => import(
    /* webpackChunkName: "error-v1" */
      '@/views/Error'
    ),
    props: {
      theme: 'blue'
    }
  },
  {
    path: '/followup',
    component: () => import(
    /* webpackChunkName: "followup-v1" */
      '@/views/Followup'
    )
  },
  {
    path: '/pre-populate',
    component: () => import(
    /* webpackChunkName: "prepopulate-v1" */
      '@/views/PrePopulate'
    )
  },
  {
    path: '/maintenance',
    component: () => import(
      /* webpackChunkName: "maintenance-v1" */
      '@/views/Maintenance'
    )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  pathToRegexpOptions: { strict: true },
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...PersonalLoansV1,
    ...NewMortgageV1,
    ...HomeEquityLoansV1,
    ...RefinanceMortgageV1,
    ...FoundationV1,
    ...FoundationDeclinedV1,
    ...DocumentsV1,
    ...MortgagesV2,
    ...FoundationGradV1,
    ...EvergreenV1,
    ...EvergreenAgreementV1,
    ...EvergreenAgreementBankingV1,
    ...FoundationAgreementV1,
    ...FoundationGradQualifiedV1,
    ...QuizV1,
    ...SPLAgreementV1,
    ...routes
  ]
})

router.beforeEach(async (to, from, next) => {
  try {
    let shouldContinue = true
    const isForward = to.meta.index > from.meta.index
    const childRoute = from.matched[1]
    const skipValidation = to.params.skipValidation

    if (childRoute && isForward) {
      const VueComponent = childRoute.instances.default
      if (VueComponent && VueComponent.$refs.observer && !skipValidation) {
        shouldContinue = await VueComponent.$_navigation_validateStep()
        VueComponent.submitted = true
      }
    }

    // Temporary fix for a known Vue Router bug with next(false)
    // Vue Router Github Issue - https://github.com/vuejs/vue-router/issues/2814
    if (shouldContinue) {
      next()
    } else {
      next({ path: from.path, replace: true })
    }
    store.dispatch('resetDateTime')
  } catch (error) {
    console.warn(error)
  }
})

export default router
