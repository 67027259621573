<template>
  <div
    class="app-progress-bar"
    :class="{'app-progress-bar--contact-code': isContactCodePage}"
  >
    <div class="app-progress-bar__wrapper">
      <div
        v-if="progressLabel"
        class="app-progress-bar__label"
      >
        {{ progressLabel }}
      </div>

      <div
        class="app-progress-bar__empty"
      >
        <div
          :class="['app-progress-bar__filled', bgColorClass()]"
          :style="filledBarStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>

import theme from '@/mixins/theme'

export default {
  // Name
  name: 'AppProgressBar',

  mixins: [theme],

  // Props
  props: {
    progressLabel: {
      type: String,
      default: ''
    },
    totalSteps: {
      type: Number,
      default: 1,
      required: true
    },
    currentStepIndex: {
      type: Number,
      default: 0,
      required: true
    },
    theme: {
      type: String,
      default: 'green'
    }
  },

  // Computed
  computed: {
    filledBarWidth () {
      if (this.currentStepIndex > this.totalSteps) return 1
      return (this.currentStepIndex) / this.totalSteps
    },
    filledBarStyle () {
      return `transform: scaleX(${this.filledBarWidth});`
    },
    isContactCodePage () {
      return this.$route.path.includes('personal-loans/contact-code')
    }
  }
}
</script>

<style scoped lang="scss">
.app-progress-bar {
  @apply container m-auto mb-40 w-full;

  &__empty,
  &__filled {
    height: 6px;
  }

  &__empty {
    @apply bg-white relative;
  }

  &__filled {
    @apply w-full left-0 absolute;

    transition: transform 500ms ease-out;
    transform-origin: 0 0;
  }

  &__label {
    @apply text-center text-gray-400 text-xs mb-1;
  }

  &__wrapper {
    @apply relative w-full m-auto;
  }

  /* Apply margin-bottom: 20px for mobile screens */
  &--contact-code {
    @screen xxs {
      @apply mb-20;
    }

    @screen xxs-only {
      @apply mb-20;
    }
  }
}
</style>
