<script>
/**
 * <hidden-fields></hidden-fields>
 * @desc component to handle the hidden fields and
 * URL params that need to be passed with the application
 */

import cookies from '@/mixins/cookies'
import DataLayer from '@/utilities/data-layer'
import * as MORTGAGE from '@/constants/mortgages'
import 'url-polyfill'

export default {
  // Name
  name: 'AppHiddenFields',

  // Mixins
  mixins: [cookies],

  // Data
  data () {
    return {
      urlFields: {
        'AID': 'AID',
        'AID2': 'AID2',
        'SID': 'SID',
        'SID2': 'SID2',
        'utmCampaign': 'utm_campaign',
        'utmContent': 'utm_content'
      },
      utmParamMap: {
        'SID': 'utm_source',
        'SID2': 'utm_medium',
        'AID': 'utm_campaign',
        'AID2': 'utm_content',
        'AD_Source': 'utm_term',
        'utmCampaign': 'utm_campaign',
        'utmContent': 'utm_content'
      },
      referrerURLs: [
        { string: 'facebook', url: 'www.facebook.com' },
        { string: 'fb', url: 'www.facebook.com' },
        { string: 'google', url: 'www.google.com' },
        { string: 'instagram', url: 'www.instagram.com' },
        { string: 'msn', url: 'www.msn.com' },
        { string: 'yahoo', url: 'www.yahoo.com' },
        { string: 'bing', url: 'www.bing.com' },
        { string: 'taboola', url: 'www.taboola.com' },
        { string: 'kijiji', url: 'www.kijiji.com' },
        { string: 'email', url: 'www.canadadrivesemail.com' },
        { string: 'twitter', url: 'www.twitter.com' },
        { string: 'autotrader', url: 'www.autotrader.com' }
      ],
      countryCode: 'CA'
    }
  },

  // Computed
  computed: {
    variation () {
      return this.$route.matched[0].meta?.variation || this.$route.matched[0].components.default.meta?.variation
    },

    appName () {
      return this.$route.matched[0].meta?.appName || this.$route.matched[0].components.default.meta?.appName
    },

    disableAppLandedEvent () {
      return this.$route.matched[0].meta.disableAppLandedEvent
    },

    trackingState () {
      return this.$store.state.tracking
    },

    financingType () {
      let financeType
      switch (this.$route.query.financing_type) {
        case MORTGAGE.NEW_PARAM :
          financeType = MORTGAGE.NEW
          break
        case MORTGAGE.REFINANCE_PARAM :
          financeType = MORTGAGE.REFINANCE
          break
        case MORTGAGE.HOME_EQUITY_PARAM :
          financeType = MORTGAGE.HOME_EQUITY
          break
        default :
          financeType = MORTGAGE.NEW
      }
      return financeType
    },
    /**
     * Example App Sources:
     *
     * springloan-usl-m-v2 (Spring Personal Loan)
     * springloan-usl-d-v2   (Spring Personal Loan)
     *
     *
     * springmortgage-new-m-v1 (Mortgage New)
     * springmortgage-new-d-v1 (Mortgage New)
     * springmortgage-heq-m-v1 (Mortgage Home Equity Loan)
     * springmortgage-heq-d-v1 (Mortgage Home Equity Loan)
     * springmortgage-ref-m-v1 (Mortgage Refinancing)
     * springmortgage-ref-d-v1 (Mortgage Refinancing)
     */
    app_source () {
      return `${this.appName}-${this.deviceType}-${this.variation}`
    },

    deviceType () {
      const deviceType = navigator.userAgent.match(/Mobile|Tablet|Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)
        ? 'm'
        : 'd'

      this.$store.dispatch('setDeviceType', deviceType)

      return deviceType
    },

    isPersonalLoan () {
      return this.$store.getters['loans/isPersonalLoan']
    },

    isMortgage () {
      return this.$store.getters['loans/isMortgage']
    },

    leadType () {
      return this.$route.matched[0].meta.leadType
    },

    fbclid () {
      return this.$route.query['fbclid']
    },

    fbc () {
      return this.$store.state.tracking.fbc
    },

    fbp () {
      return this.$store.state.tracking.fbp
    }
  },

  // Mounted
  mounted () {
    this.setFacebook()
    this.setAppSource()
    this.setLeadType()
    this.setFinancingType()
    this.setVariation()
    this.setURLFields()
    this.setLeadSource()
    this.setAdSource()
    this.setReferrerURL()
    this.setUserAgent()
    this.setWindowHiddenFields()
    this.setGclid()
    this.setUtmCampaign()
    this.setUtmContent()
    this.setTtclid()
    this.setSubId()
    this.setMsclkid()
  },

  // Methods
  methods: {
    /**
    * Set the app_source and country_code fields in the store
    *
    */
    setAppSource () {
      this.$store.dispatch('setSource', this.app_source)
      this.$store.dispatch('setCountryCode', this.countryCode)

      if (this.disableAppLandedEvent) return

      let event = 'app_landed'
      if (this.isPersonalLoan) {
        event = 'spring_ploan_app_landed'
        window.rudderanalytics.identify({
          fbc: this.fbc || null,
          fbp: this.fbp || null
        })
        window.rudderanalytics.track(event)
      } else if (this.isMortgage) event = 'spring_mortgage_app_landed'

      DataLayer.pushObject({
        event: event,
        app_source: this.app_source
      })
    },

    setLeadType () {
      if (this.leadType) this.$store.dispatch('setLeadType', this.leadType)
    },

    /**
    * Set the financing_type fields in store
    *
    */
    setFinancingType () {
      if (this.financingType) this.$store.dispatch('setFinancingType', this.financingType)
      return this.financingType
    },

    /**
    * Set the app_source and country_code fields in the store
    */
    setUserAgent () {
      this.$store.dispatch('setUserAgent', navigator.userAgent)
    },

    /**
    * Set the variation version in the store
    */
    setVariation () {
      this.$store.dispatch('setVariation', this.variation)
    },

    /**
    * Set the URL param fields
    * @desc loop through the urlFields and update the store
    */
    setURLFields () {
      const trackingParams = Object.keys(this.$store.getters['trackingParams'])

      trackingParams.forEach(trackingParam => {
        // Prioritize CD param value over UTM param value
        const urlParamValue = this.$route.query[trackingParam] || this.$route.query[this.utmParamMap[trackingParam]]
        if (urlParamValue) {
          this.$store.dispatch(`set${this.capitalize(trackingParam)}`, urlParamValue)
        } else {
          this.$store.dispatch(`set${this.capitalize(trackingParam)}`, '')
        }
      })
    },

    /**
     * Capitalize the first letter
     */
    capitalize (str) {
      return str[0].toUpperCase() + str.substring(1)
    },

    /**
     * Retrieves the URL parameter based on key or the corresponding tracking cookie
     */
    getParamOrCookie (key) {
      if (this.$route.query[key]) {
        // Return a CD Param
        return this.$route.query[key]
      } else if (this.$route.query[this.utmParamMap[key]]) {
        // Return a UTM param
        return this.$route.query[this.utmParamMap[key]]
      }
    },

    /**
    * Set the ad_source field in the store
    * Uses the most recent 'AID' value
    */
    setAdSource () {
      this.$store.dispatch('setAdSource', this.getParamOrCookie('AID'))
    },

    /**
    * Set the utm_campaign field in the store
    */
    setUtmCampaign () {
      this.$store.dispatch('setUtmCampaign', this.getParamOrCookie('utmCampaign'))
    },

    /**
    * Set the utm_content field in the store
    */
    setUtmContent () {
      this.$store.dispatch('setUtmContent', this.getParamOrCookie('utmContent'))
    },

    /**
    * Set the lead_source field in the store
    */
    setLeadSource () {
      let SID
      if (this.$route.query['SID']) {
        SID = this.$route.query['SID']
      } else if (this.$route.query['utm_source']) {
        SID = this.$route.query['utm_source']
      }
      const leadSource = this.generateLeadSource(SID)

      this.$store.dispatch('setLeadSource', leadSource)
    },

    /**
    * Set the referrer_url field in the store
    */
    setReferrerURL () {
      const SID = this.getParamOrCookie('SID')

      if (!SID) { return }

      for (let item of this.referrerURLs) {
        if (SID.indexOf(item.string) !== -1) {
          this.$store.dispatch('setReferrerUrl', item.url)
          return
        }
      }
    },

    /**
    * Sets the window.cd_hidden_fields value
    */
    setWindowHiddenFields () {
      window.cd_hidden_fields = {
        aid: this.trackingState.AID,
        ad_source: this.trackingState.ad_source,
        sid: this.trackingState.SID,
        lead_source: this.trackingState.lead_source
      }
    },

    /**
    * Generates lead_source string
    * @param SID
    */
    generateLeadSource (SID) {
      let leadSource
      if (SID) {
        leadSource = SID.includes('-')
          ? SID.split('-')[0]
          : SID
      } else {
        leadSource = 'Organic / Spring'
      }
      return leadSource
    },

    /**
    * Add gclid on cookies and send it to the Lead
    */
    setGclid () {
      const gclidParam = this.$route.query['gclid']

      const gclsrcParam = this.$route.query['gclsrc']
      const isGclsrcValid = !gclsrcParam || gclsrcParam.includes('aw')

      const expiryDate = this.$_cookies_getExpiryDate(90)

      if (gclidParam && isGclsrcValid) {
        this.$_cookies_setItem(
          'gclid',
          gclidParam,
          expiryDate
        )
      }

      const gclid = gclidParam || this.$_cookies_getItem('gclid')

      if (gclid) {
        this.$store.dispatch('setGclid', gclid)
      }
    },

    /**
    * Add msclkid on cookies and send it to the Lead
    */
    setMsclkid () {
      const msclkidParam = this.$route.query['msclkid']

      const expiryDate = this.$_cookies_getExpiryDate(90)

      if (msclkidParam) {
        this.$_cookies_setItem(
          'msclkid',
          msclkidParam,
          expiryDate
        )
      }

      const msclkid = msclkidParam || this.$_cookies_getItem('msclkid')

      if (msclkid) {
        this.$store.dispatch('setMsclkid', msclkid)
      }
    },

    /**
     * Set facebook clickid, browserid and loginid if query parameter exists
     */
    setFacebook () {
      if (this.fbclid) {
        let fbc = this.$_cookies_getItem('_fbc')
        const time = Date.now()
        // in the event where we have fbclid but not the _fbc cookie
        // generate string based off of fbclid query param
        if (!fbc) fbc = `fb.1.${time}.${this.fbclid}`
        this.$store.dispatch('setFbc', fbc)

        const fbp = this.$_cookies_getItem('_fbp')
        if (fbp) this.$store.dispatch('setFbp', fbp)
      }
    },

    setTtclid () {
      const ttclid = this.$route.query['ttclid']
      if (ttclid) {
        const ttp = this.$_cookies_getItem('_ttp')
        this.$store.dispatch('setTtp', ttp)
        this.$store.dispatch('setTtclid', ttclid)
      }
    },

    setSubId () {
      const subId = this.$route.query['SUB_ID']
      if (subId) this.$store.dispatch('setSubId', subId)
    }

  },

  // Render
  render: () => {
    return undefined
  }
}
</script>
