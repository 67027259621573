import * as ROUTE_PATH from '../../constants/route-paths'

const routeMap = [
  {
    path: ROUTE_PATH.LOAN_AMOUNT,
    fields: [
      'Loan_Amount__c'
    ]
  },
  {
    path: ROUTE_PATH.CREDIT_RATING,
    fields: [
      'Estimated_Credit_Rating__c'
    ]
  },
  {
    path: ROUTE_PATH.EMPLOYMENT_STATUS,
    fields: [
      'Source_of_Income__c'
    ]
  },
  {
    path: ROUTE_PATH.INCOME_DETAILS_FREQUENCY,
    fields: [
      'Monthly_Income__c'
    ]
  },
  {
    path: ROUTE_PATH.INCOME_DURATION,
    fields: [
      'EmployerMonths__c',
      'EmployerYears__c'
    ]
  },
  {
    path: ROUTE_PATH.HOME_ADDRESS,
    fields: [
      'Address'
    ]
  },
  {
    path: ROUTE_PATH.DATE_OF_BIRTH,
    fields: [
      'BIrthdate__c'
    ]
  },
  {
    path: ROUTE_PATH.CONTACT_PHONE,
    fields: [
      'twilio_verified'
    ]
  },
  {
    path: process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATH.VERIFY_BANK : ROUTE_PATH.FINANCIAL_SELECTOR,
    fields: [
      'flinks'
    ]
  },
  {
    path: ROUTE_PATH.VERIFY_IDENTITY,
    fields: [
      'photo_id'
    ]
  },
  {
    path: ROUTE_PATH.VERIFY_INCOME,
    fields: [
      'proof_of_income'
    ]
  },
  {
    path: ROUTE_PATH.VERIFY_ADDRESS,
    fields: [
      'proof_of_address'
    ]
  }
]

const plDeclinedRouteMap = [
  {
    path: ROUTE_PATH.PLAN_SELECTION,
    fields: [
      'Loan_Product__c'
    ]
  },
  {
    path: ROUTE_PATH.PAYMENT_SUMMARY,
    fields: [
      'Agreement_Accepted__c'
    ]
  }
]

const foundationRouteMap = [
  {
    path: ROUTE_PATH.CREDIT_GOALS,
    fields: [
      'credit_goals__c'
    ]
  },
  {
    path: ROUTE_PATH.EMPLOYMENT_STATUS,
    fields: [
      'Source_of_Income__c'
    ]
  },
  {
    path: ROUTE_PATH.INCOME_DETAILS_FREQUENCY,
    fields: [
      'Monthly_Income__c'
    ]
  },
  {
    path: ROUTE_PATH.INCOME_DURATION,
    fields: [
      'EmployerMonths__c',
      'EmployerYears__c'
    ]
  },
  {
    path: ROUTE_PATH.HOME_ADDRESS,
    fields: [
      'Address'
    ]
  },
  {
    path: ROUTE_PATH.DATE_OF_BIRTH,
    fields: [
      'BIrthdate__c'
    ]
  },
  {
    path: ROUTE_PATH.CONTACT_PHONE,
    fields: [
      'twilio_verified'
    ]
  },
  {
    path: process.env.VUE_APP_DISABLE_FI_SELECTOR ? ROUTE_PATH.VERIFY_BANK : ROUTE_PATH.FINANCIAL_SELECTOR,
    fields: [
      'flinks'
    ]
  },
  {
    path: ROUTE_PATH.PLAN_SELECTION,
    fields: [
      'Loan_Product__c'
    ]
  },
  {
    path: ROUTE_PATH.PAYMENT_SUMMARY,
    fields: [
      'Agreement_Accepted__c'
    ]
  }
]

export default {

  state: {
    Address: false,
    BIrthdate__c: false,
    Email: false,
    EmployerMonths__c: false,
    EmployerYears__c: false,
    Estimated_Credit_Rating__c: false,
    FirstName: false,
    LastName: false,
    Loan_Amount__c: false,
    Monthly_Income__c: false,
    Phone: false,
    Purpose_of_Loan__c: false,
    Source_of_Income__c: false,
    flinks: false,
    twilio_verified: false,
    is_converted: false,
    is_in_progress: false,
    is_submitted: false,
    photo_id: false,
    proof_of_address: false,
    proof_of_income: false,
    uid: '',
    Agreement_Accepted__c: false,
    Loan_Product__c: false,
    Harvest_Page_Landed__c: false
  },

  getters: {
    getNextStepForReturningCustomer (state, getters, rootState) {
      const flinksEnabled = process.env.VUE_APP_FLINKS_ENABLED
      let path
      routeMap.some(route => {
        if (route.path === ROUTE_PATH.VERIFY_BANK && !flinksEnabled) return false
        const fieldsSubmitted = route.fields.some(field => {
          return state[field]
        })
        if (!fieldsSubmitted) {
          path = route.path
          return true
        }
      })
      return path
    },

    getNextStepsForReturningPLDeclinedCustomer (state) {
      let path
      // loop through plDeclinedRouteMap looking for a step that has not been completed, return undefined if all steps have been completed
      plDeclinedRouteMap.some(route => {
        const fieldsSubmitted = route.fields.some(field => {
          return state[field]
        })
        if (!fieldsSubmitted) {
          path = route.path
          return true
        }
      })
      return path
    },

    getNextStepsForFoundationReturningCustomer (state) {
      let path
      // loop through foundationRouteMap looking for a step that has not been completed, return undefined if all steps have been completed
      foundationRouteMap.some(route => {
        const fieldsSubmitted = route.fields.some(field => {
          return state[field]
        })
        if (!fieldsSubmitted) {
          path = route.path
          return true
        }
      })
      return path
    },

    getPeronalDetailsApplicationState (state, getters, rootState) {
      const personalDetailsPropertyMap = routeMap.slice(0, 8)
      // Inverse the returned Bool value, as a true return means at least one route has no true field
      const personalDetailsComplete = !personalDetailsPropertyMap.some(route => {
        // going through the fields looking for one true value
        const fieldsSubmitted = route.fields.some(field => {
          return state[field]
        })
        // if none of the route fields are true, return true and exit loop
        return !fieldsSubmitted
      })
      return personalDetailsComplete
    },
    getFdnPersonalDetailsApplicationState (state, getters, rootState) {
      const personalDetailsPropertyMap = foundationRouteMap.slice(0, 7)
      // Inverse the returned Bool value, as a true return means at least one route has no true field
      const personalDetailsComplete = !personalDetailsPropertyMap.some(route => {
        // going through the fields looking for one true value
        const fieldsSubmitted = route.fields.some(field => {
          return state[field]
        })
        // if none of the route fields are true, return true and exit loop
        return !fieldsSubmitted
      })
      return personalDetailsComplete
    }
  },

  mutations: {
    SET_RETURNING_CUSTOMER (state, returningCustomerState) {
      Object.assign(state, returningCustomerState)
    },

    SET_IS_IN_PROGRESS (state, isInProgress) {
      state.is_in_progress = isInProgress
    },

    SET_HARVEST_PAGE (state) {
      state.Harvest_Page_Landed__c = true
    }
  },

  actions: {
    setReturningCustomer ({ commit }, returningCustomerState) {
      commit('SET_RETURNING_CUSTOMER', returningCustomerState)
    },

    setIsInProgress ({ commit }, isInProgress) {
      commit('SET_IS_IN_PROGRESS', isInProgress)
    },

    updateHarvestPage ({ commit }) {
      commit('SET_HARVEST_PAGE')
    }
  }
}
