<template>
  <div>
    <div
      id="fc-pre-chat-form"
      class="fc-pre-chat-form"
    >
      <!-- Freshchat pre-chat form will be injected here -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FreshChat',
  computed: {
    firstName () {
      return this.$store.state.customer.first_name
    },

    lastName () {
      return this.$store.state.customer.last_name
    },

    email () {
      return this.$store.state.customer.email
    },
    birthDate () {
      return this.$store.state.customer.birth_date
    },
    postalCode () {
      return this.$store.state.location.postal_code
    },

    appSource () {
      return this.$store.state.tracking.app_source
    }
  },
  mounted () {
    const vm = this // reference to `this`
    window.fcSettings = {
      onInit () {
        window.fcWidget.on('widget:loaded', function (resp) {
          // lets hide the loading spinner after this is loaded
          vm.$store.dispatch('setChatStarted', true)
        })

        window.fcWidget.on('widget:closed', function (resp) {
          // lets hide the loading spinner after this is loaded
          vm.$emit('close-chat')
        })
      }
    }
    // Freshchat widget configuration
    window.fcWidgetMessengerConfig = {
      // SET FRESHCHAT INFO HERE FROM FORM OR APP FLOW
      open: false,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      meta: {
        cf_date_of_birth: this.birthDate,
        cf_postal_code: this.postalCode,
        cf_app_source: this.appSource
      },

      config: {
        content: {
          actions: {
            tab_chat: 'Chat'
          },
          headers: {
            chat: 'Chat With Us'
          }
        },
        headerProperty: {
          hideChatButton: false
        }
      }
    }

    const script2 = document.createElement('script')
    script2.src = '//fw-cdn.com/12099268/4613446.js'
    script2.setAttribute('chat', 'true')
    document.body.appendChild(script2)
  }
}
</script>
